.running-ripples-container {
  position: relative;
  height: 30%;
  overflow: hidden;
  width: 99.99vw;
  margin-top: 30px;
}

.running-ripple {
  position: absolute;
  visibility: visible;
  top: 0;
  left: -130px;
  width: 130px;
  -webkit-animation-name: box;
  -webkit-animation-duration: 7.2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: box;
  animation-duration: 7.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-radius: 50%;
}

.second-ripple {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.third-ripple {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

.fourth-ripple {
  -webkit-animation-delay: 3.6s;
  animation-delay: 3.6s;
}

.fifth-ripple {
  -webkit-animation-delay: 4.8s;
  animation-delay: 4.8s;
}

.sixth-ripple {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
@-webkit-keyframes box {
  0% {
    -webkit-transform: translateX(0);
  }

  50%,
  100% {
    -webkit-transform: translateX(calc(99.99vw + 130px));
  }
}

@keyframes box {
  0% {
    transform: translateX(0);
  }

  50%,
  100% {
    transform: translateX(calc(99.99vw + 130px));
  }
}
