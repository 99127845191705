@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
html,
body,
#root {
  height: 100%;
  width: 100%;
}

p,
span,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
ul,
li {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

input {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #131418;
  /* overscroll-behavior-y: contain; */
}

::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url(/static/media/BrandonGrotesqueRegular.cac94907.otf) format('opentype');
}

@font-face {
  font-family: 'Brandon Printed Two';
  src: url(/static/media/BrandonPrintedTwo.65439f00.ttf) format('truetype');
}

@font-face {
  font-family: 'Cormorant';
  src: url(/static/media/Cormorant-VariableFont_wght.2f3813cd.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eczar';
  src: url(/static/media/Eczar-VariableFont_wght.91107feb.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-VariableFont_wdth,wght.071a1bec.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pacifico';
  src: url(/static/media/Pacifico-Regular.c0d735fc.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(/static/media/Poppins-Regular.35d26b78.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url(/static/media/Raleway-VariableFont_wght.575ec9e6.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Amatic';
  src: url(/static/media/AmaticSC-Regular.fd997116.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Saira';
  src: url(/static/media/Saira-Regular.853021c1.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair';
  src: url(/static/media/Playfair_144pt-Regular.d68bf878.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

#canvas-wrap {
  width: auto;
  border: 2px #ccc solid;
  overflow: hidden;
  margin: 15px auto;
  border-radius: 1000px;
  background-color: #fff;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(135deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(135deg, transparent 75%, #ccc 75%);
  background-size: 24px 24px; /* Must be a square */
  background-position: 0 0, 12px 0, 12px -12px, 0px 12px; /* Must be half of one side of the square */
}

.main-canvas {
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.control {
  margin: 10px 0;
}

.running-ripples-container {
  position: relative;
  height: 30%;
  overflow: hidden;
  width: 99.99vw;
  margin-top: 30px;
}

.running-ripple {
  position: absolute;
  visibility: visible;
  top: 0;
  left: -130px;
  width: 130px;
  -webkit-animation-name: box;
  -webkit-animation-duration: 7.2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: box;
  animation-duration: 7.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-radius: 50%;
}

.second-ripple {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.third-ripple {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

.fourth-ripple {
  -webkit-animation-delay: 3.6s;
  animation-delay: 3.6s;
}

.fifth-ripple {
  -webkit-animation-delay: 4.8s;
  animation-delay: 4.8s;
}

.sixth-ripple {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
@-webkit-keyframes box {
  0% {
    -webkit-transform: translateX(0);
  }

  50%,
  100% {
    -webkit-transform: translateX(calc(99.99vw + 130px));
  }
}

@keyframes box {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  50%,
  100% {
    -webkit-transform: translateX(calc(99.99vw + 130px));
            transform: translateX(calc(99.99vw + 130px));
  }
}

