@font-face {
  font-family: 'Cormorant';
  src: url('./fonts/Cormorant-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eczar';
  src: url('./fonts/Eczar-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-VariableFont_wdth\,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pacifico';
  src: url('./fonts/Pacifico-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Amatic';
  src: url('./fonts/AmaticSC-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Saira';
  src: url('./fonts/Saira-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair';
  src: url('./fonts/Playfair_144pt-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

#canvas-wrap {
  width: auto;
  border: 2px #ccc solid;
  overflow: hidden;
  margin: 15px auto;
  border-radius: 1000px;
  background-color: #fff;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(135deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(135deg, transparent 75%, #ccc 75%);
  background-size: 24px 24px; /* Must be a square */
  background-position: 0 0, 12px 0, 12px -12px, 0px 12px; /* Must be half of one side of the square */
}

.main-canvas {
  transform-origin: top left;
}

.control {
  margin: 10px 0;
}
